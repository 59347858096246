import React, {Component} from 'react';
import {Col, Container, Row} from "reactstrap";


class Appointments extends Component {



    state = {"data": this.props.data}

    addNewAppointment =()=>{
        let newArr = this.state.data
        newArr.push({"Date":"", "Location": "", "Purpose": "", "Notes":[""], "Remind":false})
        this.setState({"data": newArr})
        this.props.rerender();
    }

    deleteNote = (idx, noteNumber) =>{
        let result = window.confirm("Are you sure that you want to delete?")

        if(result) {

            let newState = this.state
            console.log(newState.data[idx]['Notes'])
            newState.data[idx]['Notes'].splice(noteNumber, 1);
            this.setState(newState);
            console.log(this.state.data[idx]['Notes'])
            this.props.rerender();
        }
    }

    handleNoteChange = (e, idx, noteNumber) => {
        if (e != null) {
            let newState = this.state;
            newState.data[idx]['Notes'][noteNumber] = e.target.value;
            this.setState(newState)
            this.props.rerender();
        }

    }

        addNote = (e, idx) => {
        console.log(e.target !== e.currentTarget)
        console.log(e.target, e.currentTarget, e)
        let newState = this.state
        if(newState.data[idx]['Notes'] === undefined)
            newState.data[idx]['Notes'] = [];

        newState.data[idx]['Notes'].push('');
        console.log(newState.data[idx])
        this.setState(newState);
        this.props.rerender();
    }

    removeAppointment = (idx)=>{

        let result = window.confirm("Are you sure that you want to delete?")

        if(result) {
            let newArr = this.state.data
            newArr.splice(idx, 1);
            this.setState({"data": newArr})
            this.props.rerender();
        }


    }

    handleChange= (e, idx, title )=>{
        console.log(e)
        let newState = this.state;
        newState.data[idx][title] = e.target.value
        this.setState(newState)
        this.props.rerender();
    }


    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <h1>
                            Appointments
                        </h1>
                    </Col>
                    <Col>
                        <button style={{height: "1.5em", width:"5em", padding:"0px"}} onClick={this.addNewAppointment}>
                            Add
                        </button>
                    </Col>
                </Row>

                {this.state.data.map((value,idx) => {
                    return <Row>
                    <Col style = {{background: "#f5f5f5", border: "1px solid #e3e3e3", paddingTop: "1em", marginBottom: "1em"}}>

                            <label>
                                Date:

                                <input className={"MBSInput"}  type={"datetime-local"} value={this.state.data[idx].Date} onChange={e=>this.handleChange(e,idx, "Date")}/>

                            </label>
                            <br/>
                            <label>
                                Location:
                                <input className={"MBSInput"} value={this.state.data[idx].Location} onChange={e=>this.handleChange(e,idx, "Location")}/>

                            </label>
                            <br/>
                            <label>
                                Purpose:
                                <input className={"MBSInput"} value={this.state.data[idx].Purpose} onChange={e=>this.handleChange(e,idx, "Purpose")} />

                            </label>
                            <br/>


                        <button style={{height: "2em", width:"5em", padding:"0px"}} onClick={(e)=>{this.addNote(e,idx)}}>Add Note</button>
                        <br/>
                        <label style = {{width: "100%"}}>
                            Post Appointment Notes:
                            {this.state.data[idx]['Notes'].map((value,noteNumber) => {
                                return <div style={{display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "1em"}}><br/><textarea value={this.state.data[idx]['Notes'][noteNumber]} style = {{width:"100%", height: "5em", resize: 'none'}} onChange={(e)=>{this.handleNoteChange(e, idx, noteNumber)}}/><button className={"removeButton"} onClick={(e)=>{e.stopPropagation(); this.deleteNote(idx, noteNumber);}}> X </button><br/></div>
                                 })}
                            {this.state.data[idx]['Notes'].length === 0 && <><br/>Add a note to get started!</>}
                            </label>



                        <div style={{background: "#f5f5f5"}}>
                                <button  className={"removeButton"} style = {{float:"right", clear:"both"}} onClick={()=>{this.removeAppointment(idx)}}>Remove</button>
                            </div>
                    </Col>
                </Row>
                })}
                {this.state.data.length ===0 && <>Add an appointment to begin!</>}

            </Container>
        );
    }
}

export default Appointments;