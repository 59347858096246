import {Component} from "react";
import "../stylesheets/MEDIPage.css"
import {Auth, Storage} from "aws-amplify";

class MEDIPage extends Component {

    state = {
        "name": "",
        "address": "",
        "phone": "",
        "sex":"",
        "birthdate": "",
        "bloodType" : "",
        "medicalNumbers": [],
        "preexistingConditions": [],
        "medications": [],
        "medicationAllergies": [],
        "environmentalAllergies": [],
        "OTCDrugs": [],
        "supplements": [],
        "doctors": [],
        "contacts": [],
        organDonor: false
    }

    async componentDidMount()
    {
        let fileExists = true;
        try {
            const result = await Storage.getProperties('MEDI.json');
            console.log('File Properties ', result);
        } catch (error) {
            console.log('Error ', error);
            fileExists = false;

        }

        let result = 0;
        if(fileExists) {
            try {
                Storage.configure({level: 'private'});
                result = await Storage.get("MEDI.json", {validateObjectExistence:true, download: true, cacheControl: 'no-cache'})
            } catch (e) {
                console.log(e)
            }
        }
        try {
            if (fileExists && result.Body != null) {
                let body = result.Body;
                console.log(body)
                let json = await new Response(body).json();
                console.log("JSON", json)
                let oldState = this.state
                oldState.options = Object.keys(json);
                if (oldState.options.length > 0)
                    oldState.option = oldState.options[0];

                oldState.selected = 0
                this.data = json
                this.setState(json)
            }
        }
        catch(e)
        {
            console.log(e)
        }
    }

    async sha256 (message){
        // encode as UTF-8
        const msgBuffer = new TextEncoder().encode(message);

        // hash the message
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

        // convert ArrayBuffer to Array
        const hashArray = Array.from(new Uint8Array(hashBuffer));

        // convert bytes to hex string
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        return hashHex;
    }

    async save()
    {
        Storage.configure({ level: 'private' });
        let newId = await this.sha256(await Auth.currentUserCredentials().identityId);
        let response = await Storage.put("MEDI.json", this.state)
        Storage.configure({level:'public', bucket:'mbsuserdatabucket142907-dev'})
        await Storage.put(newId+".json", this.state)
    }

     formatPhoneNumber = (e, section) => {
            let formattedNumber;
            const { name, value } = e.target;
            const { length } = value;
            // Filter non numbers
            const regex = () => value.replace(/[^0-9\.]+/g, "");
            // Set area code with parenthesis around it
            const areaCode = () => `(${regex().slice(0, 3)})`;

            // Set formatting for first six digits
            const firstSix = () => `${areaCode()} ${regex().slice(3, 6)}`;

            // Dynamic trail as user types
            const trailer = (start) => `${regex().slice(start,
                regex().length)}`;
            if (length < 3) {
                // First 3 digits
                formattedNumber = regex();
            } else if (length === 4) {
                // After area code
                formattedNumber = `${areaCode()} ${trailer(3)}`;
            } else if (length === 5) {
                // When deleting digits inside parenthesis
                formattedNumber = `${areaCode().replace(")", "")}`;
            } else if (length > 5 && length < 9) {
                // Before dash
                formattedNumber = `${areaCode()} ${trailer(3)}`;
            } else if (length >= 10) {
                // After dash
                formattedNumber = `${firstSix()}-${trailer(6)}`;
            }
            const formattedObject = {
                target: { name: name, value: formattedNumber }
            };
            this.handleInputChange(formattedObject, section);
        }

    handleNestedPhoneChange = (e, idx, subsection, section) =>
    {
        let formattedNumber;
        const { name, value } = e.target;
        const { length } = value;
        // Filter non numbers
        const regex = () => value.replace(/[^0-9\.]+/g, "");
        // Set area code with parenthesis around it
        const areaCode = () => `(${regex().slice(0, 3)})`;

        // Set formatting for first six digits
        const firstSix = () => `${areaCode()} ${regex().slice(3, 6)}`;

        // Dynamic trail as user types
        const trailer = (start) => `${regex().slice(start,
            regex().length)}`;
        if (length < 3) {
            // First 3 digits
            formattedNumber = regex();
        } else if (length === 4) {
            // After area code
            formattedNumber = `${areaCode()} ${trailer(3)}`;
        } else if (length === 5) {
            // When deleting digits inside parenthesis
            formattedNumber = `${areaCode().replace(")", "")}`;
        } else if (length > 5 && length < 9) {
            // Before dash
            formattedNumber = `${areaCode()} ${trailer(3)}`;
        } else if (length >= 10) {
            // After dash
            formattedNumber = `${firstSix()}-${trailer(6)}`;
        }
        const formattedObject = {
            target: { name: name, value: formattedNumber }
        };
        this.handleNestedMultiInputChange(formattedObject, idx, subsection, section);

    }
    handleNestedMultiInputChange = (e, idx, subsection, section) =>
    {
        if(e != null) {
            let newState = this.state;
            newState[section][idx][subsection] = e.target.value;
            this.setState(newState)
        }
    }

    addContact()
    {
        let newState = this.state;
        this.state['contacts'].push({"name":"", "phone":"", "relationship":""})
        this.setState(newState)

    }
    addRow=(key) => {
        console.log(this)
        let oldState = this.state;
        oldState[key].push('');
        console.log(this.state)
        console.log(oldState)
        this.setState(oldState);
    }

    removeRow = (row, section, e) => {
        e.stopPropagation();
        e.preventDefault();
        console.log("rr")
        console.log(row, section);
        console.log(this.state)
        let oldState = this.state;
        console.log(oldState[section])
        oldState[section].splice(row, 1);
        console.log(oldState[section])

        this.setState(oldState);
    }
    handleMultiInputChange = (e, idx, section) => {
        if(e != null) {
            let newState = this.state;
            newState[section][idx] = e.target.value;
            this.setState(newState)
        }
    }

    handleInputChange =(e, section) =>
    {
        if(e != null) {
            let newState = this.state;
            newState[section] = e.target.value;
            this.setState(newState)
        }
    }

    addDoctor = () =>
    {
        let newState = this.state;
        this.state['doctors'].push({"name":"", "phone":""})
        this.setState(newState)
    }
    render() {
        console.log(this)
        return(
            <>
                <form>
                    <label>
                        <span> Name: </span>
                        <input onChange={(e) => {this.handleInputChange(e, "name")}} value={this.state['name']} />
                    </label>
                    <br/>
                    <label>
                        <span> Address: </span>
                        <input onChange={(e) => {this.handleInputChange(e, "address")}} value={this.state['address']} />
                    </label>
                    <br/>
                    <label>
                        <span> Phone: </span>
                        <input onChange={(e)=>{this.formatPhoneNumber(e, "phone")}} value={this.state['phone']} type="tel" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" />
                    </label>
                    <br/>
                    <label>
                        <span> Pre-existing conditions: </span>
                        <button type="button" className='addButton' onClick={() => {
                            this.addRow('medicalNumbers')
                        }}> Add + </button>
                    </label>
                    {this.state['medicalNumbers'].map((value, idx) => {
                        return (<><div style={{display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            paddingBottom: "1em",
                            flexDirection: "row"}}><input onChange={(e)=>this.handleMultiInputChange(e,idx, "medicalNumbers")} class = "listInput" value={this.state["medicalNumbers"][idx]}/> <button type="button" class = "removeButton" onClick={ (e) => {this.removeRow(idx, "medicalNumbers",e)}}>X</button></div></>)
                    })}
                    <br/>
                    <label>
                        <span> Sex: </span>
                        <select value={this.state['sex']} onChange={(e) => {this.handleInputChange(e, "sex")}}>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>

                        </select>
                    </label>
                    <br/>
                    <label>
                        <span> Birthdate: </span>
                        <input value={this.state['birthdate']} onChange={(e) => {this.handleInputChange(e, "birthdate")}} type="date"/>
                    </label>
                    <br/>
                    <label>
                        <span> Pre-existing conditions: </span>
                        <button type="button" className='addButton' onClick={() => {
                            this.addRow('preexistingConditions')
                        }}> Add + </button>
                    </label>
                        {this.state['preexistingConditions'].map((value, idx) => {
                            return (<><div style={{display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                                paddingBottom: "1em",
                                flexDirection: "row"}}><input onChange={(e)=>this.handleMultiInputChange(e,idx, "preexistingConditions")} class = "listInput" value={this.state["preexistingConditions"][idx]}/> <button type="button" class = "removeButton" onClick={ (e) => {this.removeRow(idx, "preexistingConditions",e)}}>X</button></div></>)
                        })}
                    <br/>
                    <label>
                        <span> Medications: </span>
                        <button type="button" className='addButton' onClick={() => {
                            this.addRow('medications')
                        }}> Add + </button>
                    </label>

                    {this.state['medications'].map((value, idx) => {
                            return (<><div style={{display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                                paddingBottom: "1em",
                                flexDirection: "row"}}><input class = "listInput" onChange={(e)=>this.handleMultiInputChange(e,idx, "medications")} value={this.state["medications"][idx]}/> <button type="button" class = "removeButton" onClick={ (e) => {this.removeRow(idx, "medications",e)}}>X</button></div></>)

                        })}
                    <br/>
                    <label>
                        <span> Medication Allergies: </span>
                        <button type="button" className='addButton' onClick={() => {
                            this.addRow('medicationAllergies')
                        }}> Add + </button>
                    </label>

                    {this.state['medicationAllergies'].map((value, idx) => {
                            return (<><div style={{display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                                paddingBottom: "1em",
                                flexDirection: "row"}}><input onChange={(e)=>this.handleMultiInputChange(e,idx, "medicationAllergies")} class = "listInput" value={this.state["medicationAllergies"][idx]}/> <button type="button" class = "removeButton" onClick={ (e) => {this.removeRow(idx, "medicationAllergies",e)}}>X</button></div></>)

                        })}
                    <br/>
                    <label>
                        <span> Environmental Allergies: </span>
                        <button type="button" className='addButton' onClick={() => {
                            this.addRow('environmentalAllergies')
                        }}> Add + </button>
                    </label>

                    {this.state['environmentalAllergies'].map((value, idx) => {
                            return (<><div style={{display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                                paddingBottom: "1em",
                                flexDirection: "row"}}><input class = "listInput" onChange={(e)=>this.handleMultiInputChange(e,idx, "environmentalAllergies")} value={this.state["environmentalAllergies"][idx]}/> <button type="button" class = "removeButton" onClick={ (e) => {this.removeRow(idx, "environmentalAllergies",e)}}>X</button></div></>)

                        })}
                    <br/>
                    <label>
                        <span>Over the Counter Drugs: </span>
                        <button type="button" className='addButton' onClick={() => {
                            this.addRow('OTCDrugs')
                        }}> Add + </button>
                    </label>

                    {this.state['OTCDrugs'].map((value, idx) => {
                            return (<><div style={{display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                                paddingBottom: "1em",
                                flexDirection: "row"}}><input onChange={(e)=>this.handleMultiInputChange(e,idx, "OTCDrugs")} class = "listInput" value={this.state["OTCDrugs"][idx]}/> <button type="button" class = "removeButton" onClick={ (e) => {this.removeRow(idx, "OTCDrugs",e)}}>X</button></div></>)

                        })}
                    <br/>
                    <label>
                        <span> Blood Type: </span>
                        <select value={this.state['bloodType']} onChange={(e) => {this.handleInputChange(e, "bloodType")}}>
                            <option value="A+">A+</option>
                            <option value="A-">A-</option>
                            <option value="B+">B+</option>
                            <option value="B-">B-</option>
                            <option value="AB+">AB+</option>
                            <option value="AB-">AB-</option>
                            <option value="O+">O+</option>
                            <option value="O-">O-</option>
                        </select>
                    </label>
                    <br/>
                    <label>
                        <span> Supplements: </span>
                        <button type="button" className='addButton' onClick={() => {
                            this.addRow('supplements')
                        }}> Add + </button>
                    </label>
                    {this.state['supplements'].map((value, idx) => {
                        return (<><div style={{display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            paddingBottom: "1em",
                            flexDirection: "row"}}><input onChange={(e)=>this.handleMultiInputChange(e,idx, "supplements")} class = "listInput" value={this.state["supplements"][idx]}/> <button type="button" class = "removeButton" onClick={ (e) => {this.removeRow(idx, "supplements",e)}}>X</button></div></>)

                    })}
                    <br/>
                    <label>
                        <span> Doctor: </span>
                        <button type="button" className='addButton' onClick={() => {
                            this.addDoctor()
                        }}> Add + </button>

                    </label>

                    {this.state['doctors'].map((value, idx) => {
                        return (<><div style={{display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            paddingBottom: "1em",
                            flexDirection: "row"}}>
                            <div>
                                <label style={{display:"flow"}}>
                                    <span style={{display: "flow", textAlign: "left"}}>Name</span>
                                    <input onChange={(e) => this.handleNestedMultiInputChange(e, idx,"name", "doctors")}
                                           className="listInput" value={this.state["doctors"][idx]['name']}/>
                                </label>
                                <label>
                                    <span style={{display: "flow", textAlign: "left"}}>Phone Number</span>
                                    <input onChange={(e) => this.handleNestedPhoneChange(e, idx, "phone", "doctors")}
                                           className="listInput" value={this.state["doctors"][idx]['phone']}/>
                                </label>
                                <button type="button" class = "removeButton" style={{float:"right", marginTop:"1em"}} onClick={ (e) => {this.removeRow(idx, "doctors",e)}}>X</button></div></div></>)


                    })}

                    <br/>
                    <label>
                        <span> Emergency Contacts: </span>
                        <button type="button" className='addButton' onClick={() => {
                            this.addContact()
                        }}> Add + </button>

                    </label>
                    {this.state['contacts'].map((value, idx) => {
                        return (<><div style={{display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            paddingBottom: "1em",
                            flexDirection: "row"}}>
                            <div>
                                <label style={{display:"flow"}}>
                                    <span style={{display: "flow", textAlign: "left"}}>Name</span>
                                    <input onChange={(e) => this.handleNestedMultiInputChange(e, idx,"name", "contacts")}
                                           className="listInput" value={this.state["contacts"][idx]['name']}/>
                                </label>
                                <label>
                                    <span style={{display: "flow", textAlign: "left"}}>Phone Number</span>
                                    <input onChange={(e) => this.handleNestedPhoneChange(e, idx, "phone", "contacts")}
                                           className="listInput" value={this.state["contacts"][idx]['phone']}/>
                                </label>
                                <label>
                                    <span style={{display: "flow", textAlign: "left"}}>Relationship</span>
                                    <input onChange={(e) => this.handleNestedPhoneChange(e, idx, "relationship", "contacts")}
                                           className="listInput" value={this.state["contacts"][idx]['relationship']}/>
                                </label>
                                <button type="button" class = "removeButton" style={{float:"right", marginTop:"1em"}} onClick={ (e) => {this.removeRow(idx, "contacts",e)}}>X</button></div></div></>)


                    })}

                    <br/>
                    <label>
                        <span> Organ Donor: </span>
                        <input style={{verticalAlign: "middle"}} value={this.state["organDonor"]} onChange={(e)=>{this.handleInputChange(e, "organDonor")}} type="checkbox"/>
                    </label>
                    <br/>
                    <label>
                        <span> Religious Care Directive: </span>
                       TBA
                    </label>
                    <br/>
                    <label>
                        <span> DNR Protocol: </span>
                        TBA
                    </label>

                <button type='button' onClick={()=>{this.save()}}>Save!</button>
                </form></>

        )
    }

}

export default MEDIPage;