/*
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Site from './website/components/Home'
import reportWebVitals from './reportWebVitals';
import { ProSidebarProvider } from 'react-pro-sidebar';

import "./Home.css";

ReactDOM.render(
  <React.StrictMode>
      <ProSidebarProvider>
            <Site />
      </ProSidebarProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
*/

import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
//import App from './App';
import * as serviceWorker from './serviceWorker';
// import Home from "./components/Home"
//import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
//import OurApp from "./components/Our App";
//import MyIllnessPageMobile from "./components/MyIllnessPageMobile";
//import OurApp from "./components/Our App";
import "video-react/dist/video-react.css";
import "../src/website/stylesheets/wrapperStyling.css"
import OurApp from "./website/components/Our App";
import FaQs from "./website/components/FAQs";
import App from "./App"
import MEDI from "./website/components/MEDIDisplay"

import { ReactComponent as Logo } from './logo.svg';

//
import { BrowserRouter, Route, Switch} from "react-router-dom";
import OurMission from "./website/components/Our Mission";
import Home from "./website/components/Home";
import Contact from "./website/components/Contact";
const routing = (
    <div>
        <BrowserRouter forceRefresh={true}>
            <Switch>

                <Route path="/Mission" component={OurMission}/>
                <Route path="/FAQs" component={FaQs}/>
                <Route path="/OurApp" component={OurApp}/>
                <Route path = "/ContactUs" component={Contact}/>
                <Route path = "/App" component={App}/>
                <Route path ="/MEDI" component={MEDI}/>

                <Route exact path="/" component={Home} />



            </Switch>
        </BrowserRouter>
    </div>
)

ReactDOM.render(
    routing,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();