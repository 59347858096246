import React, {Component} from 'react';
import {Col, Container, Row} from "reactstrap";

class Diagnosis extends Component {

    state = this.props.data

    handleChange= (e, title )=>{
        console.log(e)
        let newState = this.state;
        newState[title] = e.target.value
        this.setState(newState)
        this.props.rerender();
    }

    render(){
        console.log(this.state)
        return (
            <Container>
                <Row>
                    <Col>
                        <h1>
                            Diagnosis
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col style = {{background: "#f5f5f5", border: "1px solid #e3e3e3", paddingTop: "1em", marginBottom: "1em"}}>
                            <label>
                                <span> Form: </span>
                                <input className={"MBSInput"} value={this.state?.Form} onChange={(e)=>{this.handleChange(e,"Form")}}/>
                            </label>
                            <br/>
                            <label>
                                <span> Condition Diagnosis Date: </span>
                                <input className={"MBSInput"}  type ={"date"} value={this.state?.["Condition Start Date"]} onChange={(e)=>{this.handleChange(e,"Condition Start Date")}} />
                            </label>
                    </Col>
                </Row>

            </Container>
        );
    }
}

export default Diagnosis;