import React, {Component} from 'react';
import {Amplify, Storage} from 'aws-amplify';
import awsExports from '../../aws-exports';
import App from "../App";
import "../stylesheets/MEDIDisplay.css"

//Amplify.configure(awsExports);
Amplify.configure({
    Storage: {
        S3: {
            region: 'us-east-2', // (required) - Amazon S3 bucket region
            bucket: 'mbsuserdatabucket142907-dev' // (required) - Amazon S3 bucket URI
        }
    }
});


class MEDIDisplay extends Component {

    state = {
        "name": "",
        "address": "",
        "phone": "",
        "sex": "",
        "birthdate": "",
        "bloodType": "",
        "medicalNumbers": [],
        "preexistingConditions": [],
        "medications": [],
        "medicationAllergies": [],
        "environmentalAllergies": [],
        "OTCDrugs": [],
        "supplements": [],
        "doctors": [],
        "contacts": [],
        "organDonor": false
    }
    async componentDidMount() {
        let pathname = window.location.pathname;
        let query = pathname.split("/")
        let id = query[query.length - 1]
        console.log(id);
        const fetchData = async () => {
            Storage.configure({level: 'public'});
            const result = await Storage.get(id + ".json", {download: true, cacheControl: 'no-cache'});
            console.log(result)
            return result
        }
        let MEDIData = await fetchData();
        let body = MEDIData.Body;
        console.log(body)
        let json = await new Response(body).json();

        this.setState(json);
        console.log("State:", this.state)
    }

    render() {
        return(
            <>
                {this.state['name'].length > 0 &&
                <><h2> Name:</h2> <span> {this.state['name']}</span>
                <br/></>}
                {this.state['address'].length > 0 &&
                <><h2> Address:</h2> <span>{this.state['address']}</span>
                <br/></>}
                {this.state['phone'].length > 0 &&
                <><h2> Phone:</h2> <span> {this.state['phone']}</span>
                <br/></>}
                {this.state['medicalNumbers'].length > 0 &&
                <><h2> Medical Numbers: </h2>
                {this.state['medicalNumbers'].map((value) => {
                    return (<>
                        <span>{value}</span><br/></>)})}
                    </>}

                {this.state['preexistingConditions'].length > 0 &&
                <><h2> Pre-existing conditions: </h2>
                {this.state['preexistingConditions'].map((value) => {
                    return (<>
                        <span>{value}</span><br/></>)})}
                    </>}

                {this.state['sex'].length > 0 &&
                <><h2> Sex: </h2> <span>{this.state['sex']}</span>
                <br/></>}
                <h2> Birthdate: </h2> <span>{this.state['birthdate']}</span>
                <br/>
                {this.state['medications'].length > 0 &&
                <><h2> Medications: </h2>
                {this.state['medications'].map((value) => {
                    return (<><span>{value}</span><br/></>)})}
                    </>}

                {this.state['medicationAllergies'].length > 0 &&
                <><h2> Medication Allergies: </h2>
                {this.state['medicationAllergies'].map((value) => {
                    return (<><span>{value}</span><br/></>)})}
                </>}
                {this.state['environmentalAllergies'].length > 0 &&
                <><h2> Environmental Allergies: </h2>
                <br/>
                {this.state['environmentalAllergies'].map((value) => {
                    return (<><span>{value}</span><br/></>)})}
                </>}

                {this.state['OTCDrugs'].length > 0 &&
                <><h2>Over the Counter Drugs: </h2>
                <br/>
                {this.state['OTCDrugs'].map((value) => {
                    return (<><span>{value}</span><br/></>)})}
                </>}

                {this.state['bloodType'].length > 0 && <><h2> Blood Type: </h2> <span>{this.state['bloodType']}</span> <br/></>}

                {this.state['supplements'].length > 0 &&
                <><h2>Supplements: </h2>
                <br/>
                {this.state['supplements'].map((value) => {
                    return (<><span>{value}</span><br/></>)})}

                <br/></>}

                {this.state['doctors'].length > 0 &&
                    <><h2>Doctor:</h2>
                {this.state['doctors'].map((value) => {
                    return (<>
                    <h3>Name:</h3>
                    <span class="indent">{value['name']}</span>
                    <h3>Phone Number:</h3>
                    <span class="indent">{value['phone']}</span>
                    <br/></>)})}</>
                }

                {this.state['contacts'].length > 0 &&

                <><h2>Emergency Contacts:</h2>
                {this.state['contacts'].map((value) => {
                    return (<>
                        <h3>Name:</h3>
                        <span class="indent">{value['name']}</span>
                        <h3>Phone Number:</h3>
                        <span class="indent">{value['phone']}</span>
                        <h3>Relationship:</h3>
                        <span>{value['relationship']}</span>
                        <br/></>)})}
                    </>}




    </>

    )
    }
}

export default MEDIDisplay;