import React from 'react';
import logo from './logo.svg';
//import './App.css';
import Heading from "./components/Heading";
import Home from "./components/Home";

function App() {
  return (
    <Home/>
  );
}

export default App;
