import React, {Component} from 'react';
import {Col, Container, Row} from "reactstrap";
import "../stylesheets/IllnessPage.css"
import {filter} from "dom-helpers";


class Notes extends Component{
    state = {data: this.props.data, query: ""};

    addNote = () =>{
        console.log(this.state)
        let newState = this.state;
        newState.data.push({date:"", note:"", title:""})
        console.log(newState)
        this.setState(newState)
        console.log(this.state)
        this.props.rerender();
    }
    removeNote = (idx) =>{
        let result = window.confirm("Are you sure that you want to delete?")

        if(result) {

            let newState = this.state;
            newState.data.splice(idx, 1);

            this.setState(newState)
            this.props.rerender();
        }
    }

    handleChange= (e, idx, type)=>{
        console.log(e)
        let newState = this.state;
        newState.data[idx][type] = e.target.value
        this.setState(newState)
        this.props.rerender();
    }

    handleFilterChange=(e)=>
    {
        let newState = this.state;
        newState.query = e.target.value
        console.log(newState.query)
        this.setState(newState)

    }
    render() {
        console.log(this.state)
        return (
            <Container>
                <Row>
                    <Col>
                        <h2 style={{fontSize:"1em"}}>
                            Journal
                        </h2>
                    </Col>
                    <Col>
                        <button style={{height: "1.5em", width:"5em", padding:"0px"}} onClick={this.addNote}>
                            Add
                        </button>
                    </Col>

                </Row>
                <Row>
                    <label>
                        Search:
                       <input onChange={(e)=>this.handleFilterChange(e)}/>
                    </label>
                </Row>
                {this.state.data.filter((value) => {
                    console.log("Value",value)
                    console.log("Query", this.state.query)
                    return value.note.includes(this.state.query) || value.title.includes(this.state.query)
                }).map((value,idx) => {
                    return <Row>
                    <Col  style = {{background: "#f5f5f5", border: "1px solid #e3e3e3", marginBottom: "10px"}}>
                        <ul style={{listStyleType: "none"}}>
                            <li>
                                <input className={"MBSInput"} onChange={(e)=>this.handleChange(e,idx, 'title')} style ={{height:"40px", marginTop:"3em", float:'left' }} value={this.state.data[idx].title}/>
                            </li>
                            <li>
                                <textarea className={"MBSInput"} onChange={(e)=>this.handleChange(e,idx, 'note')} style ={{height:"100px", width:"400px" }} value={this.state.data[idx].note}/>
                            </li>
                            <li>
                                <input type='date' className={"MBSInput"} onChange={(e)=>this.handleChange(e,idx, 'date')} style ={{height:"40px", marginRight:"3em" }} value={this.state.data[idx].date}/>
                            </li>
                            <li style={{background: "#f5f5f5"}}>
                                <button  className={"removeButton"} style = {{float:"right", clear:"both"}} onClick={()=> {this.removeNote(idx)}}>Remove</button>
                            </li>

                        </ul>

                    </Col>
                </Row> })}

            </Container>
        );
    }
}

export default Notes;