import logo from './logo.svg';
import './App.css';
import React, {useEffect, useState} from "react";
import MyIllnessPage from "./app/components/MyIllnessPage";
import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
import styles from '@aws-amplify/ui-react/styles.css';
import { Amplify, Auth, Storage } from 'aws-amplify';
import AWS from 'aws-sdk';
import awsExports from './aws-exports';
import { Sidebar, Menu, MenuItem, SubMenu, ProSidebarProvider } from 'react-pro-sidebar';
import MEDIPage from "./app/components/MEDIPage";

Amplify.configure(awsExports);

/*
Amplify.configure({
  Auth: {

      identityPoolId: 'us-east-2:84bdf1f7-393e-4591-8db8-a26d51b0ba8c',
    // REQUIRED - Amazon Cognito Region
    region: 'us-east-2',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-east-2_YTZnFtcVc',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '5kqk6apmrfkb40p2hgbjmteqs5',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
    // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
    signUpVerificationMethod: 'code', // 'code' | 'link'


  }
});

Amplify.configure({
    aws_user_files_s3_bucket_region: 'us-east-2', // (required) - Amazon S3 bucket region
    aws_user_files_s3_bucket: 'mbs-users' // (required) - Amazon S3 bucket URI
});

*/
// You can get the current config object
function doStuff(user) {
    if (user != null) {
        user.getSession(function (err, result) {
            if (result) {
                console.log('You are now logged in.');

                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    Logins: {
                        'cognito-idp.us-east-2.amazonaws.com/us-east-2_YTZnFtcVc': result.getIdToken().getJwtToken()
                    }
                });

                console.log(AWS.config.credentials)
            }
        });
    }
}
let page = 0
function setPage(pageNumber)
{
    page = pageNumber
}


 function App({ signOut, user }) {
    //doStuff(user)
    console.log(Storage)

     console.log(user)
     let data = {}
     const [page,setPage] = useState(0)
     useEffect(() => {
         // declare the data fetching function
         const fetchData = async () => {
             Storage.configure({ level: 'private' });
             const result = await Storage.get("userData.json", {download: true});
             console.log(result)
             return result
         }

         // call the function
         //data = fetchData()
         //console.log(data)
             // make sure to catch any err);
     }, []);

    console.log(data)
  return (
      <React.StrictMode>
          <ProSidebarProvider>

          <div style={{ display: 'flex', height: '100%' }}>
          <Sidebar>
              <Menu>
                  <MenuItem onClick={() => {setPage(0)}}> My Illnesses</MenuItem>
                  <MenuItem onClick={() => {setPage(1)}}> MEDI </MenuItem>
              </Menu>
          </Sidebar>
          <main>
              <div>
                  <Button onClick={signOut}>Sign out</Button>
                  {page === 0 && <MyIllnessPage/>}
                  {page === 1 && <MEDIPage/>}
              </div>
          </main>
      </div>
          </ProSidebarProvider>
      </React.StrictMode>


  );
}
export default withAuthenticator(App);
