import React, {Component} from 'react';
import {Col, Container, Row} from "reactstrap";


class Reminders extends Component {

    state = {"appointments":this.props.appointments, "medications": this.props.medications}
    //daybutton
    daybutton = {width: "2em", height:"2em", marginLeft:".5vw"}
    selectedButton = { width: "2em", height:"2em", marginLeft:".5vw", color:"#5F0E78", background: "white", borderColor:"#5F0E78" }

    handleAppointmentToggle = (idx) =>{
        let newState = this.state;
        newState.appointments[idx].Remind = !newState.appointments[idx].Remind
        this.setState(newState)
        this.props.rerender();


    }

    getStyle = (boolVal) => {
        if (boolVal === true){
            return this.selectedButton
        }
        return this.daybutton


    }
    toggle = (idx, day) =>{
        let newState = this.state;
        console.log(newState.medications[idx].Days)
        let val = !(newState.medications[idx].Days[day])
        console.log(val)
        newState.medications[idx].Days[day] = val
        console.log(newState)

        this.setState(newState)
        this.props.rerender();
        console.log(this.state)


    }

    reformat = (date) =>{
        return date.replace("T", " ")
    }

    setTime =(e,idx)=>{
         let newState = this.state
         newState.medications[idx].Time = e.target.value
        this.setState(newState)
        this.props.rerender();

    }
    render() {
        console.log(this.state)
        return (
            <Container >
                {this.state.appointments.length > 0 &&  <Row>
                    <Col>
                        <h1  style={{fontSize:"1em"}}>
                            Reminders
                        </h1>
                    </Col>
                </Row>


                }

                {this.state.appointments.map((value,idx) => {
                    if(value.Date !== ""){
                return <Row>
                    <Col>
                        <p style={{fontSize:"1em", fontWeight:"1.5em"}}>
                            {this.reformat(value.Date.  toString())}
                        </p>
                    </Col>
                    <Col>
                        <label>
                            Remind?
                            <input type={"checkbox"} value = {value.Remind} onClick={()=>{this.handleAppointmentToggle(idx)}}></input>
                        </label>
                    </Col>

                    </Row>}})}

                {this.state.medications.length > 0 && <Row>
                        <Col>
                            <h2 style={{fontSize: "1em"}}>Medications</h2>
                        </Col>

                    </Row>}
                {this.state.medications.map((value,idx) => {
                return <>
                    <Row>
                    <Col>
                        <b> {value.Name}</b>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>Time of Day: <input type={"time"} value = {value.Time} onChange={(e)=>{this.setTime(e,idx)}}/></label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>Days of the Week</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <button style={this.getStyle(value.Days[0])} onClick={()=>{this.toggle(idx,0)}} >
                            S
                        </button>
                        <button style={this.getStyle(value.Days[1])} onClick={()=>{this.toggle(idx,1)}}>
                            M
                        </button>
                        <button  style={this.getStyle(value.Days[2])} onClick={()=>{this.toggle(idx,2)}}>
                            T
                        </button>
                        <button style={this.getStyle(value.Days[3])} onClick={()=>{this.toggle(idx,3)}}>
                            W
                        </button>
                        <button style={this.getStyle(value.Days[4])} onClick={()=>{this.toggle(idx,4)}}>
                            T
                        </button>
                        <button style={this.getStyle(value.Days[5])} onClick={()=>{this.toggle(idx,5)}}>
                            F
                        </button>
                        <button style={this.getStyle(value.Days[6])} onClick={()=>{this.toggle(idx,6)}}>
                            S
                        </button>

                    </Col>
                </Row>
                    </>})}

                {this.state.medications.length ===0 && this.state.medications.length === 0 && <>Add items to your appointments and medications to see this page in action!</>}

            </Container>
        );
    }
}

export default Reminders;