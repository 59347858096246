/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://jkt6vmlws6.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:c7fd4773-b165-49c4-9560-1098e69dccee",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_7ij0ohFSL",
    "aws_user_pools_web_client_id": "5vl7m8nb0t4vlp7u13np7ntred",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "mbsuserdatabucket142907-dev",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "REGION": "us-east-2",
    "USER_POOL_ID": "us-east-2_IM30629Y2",
    "USER_POOL_APP_CLIENT_ID": "1b8o3g4gq3rvpuhl11kqh4u3r8"
};


export default awsmobile;
